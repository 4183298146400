var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"servertable"},[_c('table',{staticClass:"table"},[_c('tbody',[_c('tr',[_vm._m(0),_c('td',{staticClass:"text-left"},[_c('span',{staticClass:"font14"},[_vm._v(_vm._s(this.pageInfo.balance))])]),_vm._m(1),_c('td',{staticClass:"text-left"},[_c('span',{staticClass:"font14"},[_vm._v(_vm._s(this.pageInfo.personName))])])]),_c('tr',[_vm._m(2),_c('td',{staticClass:"text-left"},[_c('span',{staticClass:"font14"},[_vm._v(_vm._s(this.pageInfo.personSex))])]),_vm._m(3),_c('td',{staticClass:"text-left"},[_c('span',{staticClass:"font14"},[_vm._v(_vm._s(this.pageInfo.age))])])]),_c('tr',[_vm._m(4),_c('td',{staticClass:"text-left"},[_c('span',{staticClass:"font14"},[_vm._v(_vm._s(this.pageInfo.personTel))])]),_vm._m(5),_c('td',{staticClass:"text-left"},[_c('span',{staticClass:"font14"},[_vm._v(_vm._s(this.pageInfo.delivery == 0 ? "是" : "否"))])])]),_c('tr',[_vm._m(6),_c('td',{staticClass:"text-left"},[_c('span',{staticClass:"font14"},[_vm._v(_vm._s(this.pageInfo.address))])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right",staticStyle:{"width":"180px"}},[_c('span',{staticClass:"font14"},[_vm._v("余额：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right",staticStyle:{"width":"180px"}},[_c('span',{staticClass:"font14"},[_vm._v("姓名：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right",staticStyle:{"width":"180px"}},[_c('span',{staticClass:"font14"},[_vm._v("性别：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right",staticStyle:{"width":"180px"}},[_c('span',{staticClass:"font14"},[_vm._v("年龄：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right",staticStyle:{"width":"180px"}},[_c('span',{staticClass:"font14"},[_vm._v("联系电话：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right",staticStyle:{"width":"180px"}},[_c('span',{staticClass:"font14"},[_vm._v("是否配送：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right",staticStyle:{"width":"180px"}},[_c('span',{staticClass:"font14"},[_vm._v("地址:")])])
}]

export { render, staticRenderFns }