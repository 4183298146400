<template>
  <div class="servertable">
    <table class="table">
      <tbody>
        <tr>
          <td class="text-right" style="width: 180px"><span class="font14">余额：</span></td>
          <td class="text-left">
            <span class="font14">{{ this.pageInfo.balance }}</span>
          </td>
          <td class="text-right" style="width: 180px"><span class="font14">姓名：</span></td>
          <td class="text-left">
            <span class="font14">{{ this.pageInfo.personName }}</span>
          </td>
        </tr>
        <tr>
          <td class="text-right" style="width: 180px"><span class="font14">性别：</span></td>
          <td class="text-left">
            <span class="font14">{{ this.pageInfo.personSex }}</span>
          </td>
          <td class="text-right" style="width: 180px"><span class="font14">年龄：</span></td>
          <td class="text-left">
            <span class="font14">{{ this.pageInfo.age }}</span>
          </td>
        </tr>
        <tr>
          <td class="text-right" style="width: 180px"><span class="font14">联系电话：</span></td>
          <td class="text-left">
            <span class="font14">{{ this.pageInfo.personTel }}</span>
          </td>
          <td class="text-right" style="width: 180px"><span class="font14">是否配送：</span></td>
          <td class="text-left">
            <span class="font14">{{ this.pageInfo.delivery == 0 ? "是" : "否" }}</span>
          </td>
        </tr>
        <tr>
          <td class="text-right" style="width: 180px"><span class="font14">地址:</span></td>
          <td class="text-left">
            <span class="font14">{{ this.pageInfo.address }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'BaseInfo',
  data() {
    return {
      pageInfo: {
        orgName: "",
        beOrgName: "",
        street: "",
        address: "",
        mainPerson: "",
        mainPersonTel: "",
        standard: "",
        delivery: "",
        deliveryFee: "",
        serviceTime: "",
      },
    }
  },
  computed: {},
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['mealPersonInfo']),
    initView() {
      this.getDetail()
    },
    // 查询企业详情
    getDetail() {
      this.mealPersonInfo({
        id: this.$route.params.id,
      }).then((res) => {
        if (res.code == 200) {
          console.log(res.data);
          this.pageInfo.balance = res.data.balance;
          this.pageInfo.personName = res.data.personName;
          this.pageInfo.personSex = res.data.personSex;
          this.pageInfo.delivery = res.data.delivery;
          this.pageInfo.age = res.data.age;
          this.pageInfo.personTel = res.data.personTel;     
          this.pageInfo.address = res.data.address;
          
        }
      });
    },
  },
}
</script>
<style lang="scss" scoped>
.servertable {
  overflow: auto;
}

.servertable .table {
  width: 100%;
}

.servertable .table caption {
  color: #488fe1;
  font-size: 24px;
  padding: 0;
  margin-bottom: 16px;
}

.servertable .table tr td {
  vertical-align: middle;
  font-size: 14px;
  height: 50px;
  border: 1px solid #e2e5e7;
}

.servertable .table tr td .bz {
  color: #777676;
  line-height: 31px;
  padding-left: 17px;
  padding-right: 50px;
}

.servertable .table tr td .bz header {
  min-width: 0;
}

.servertable .table tr td .bz header,
.servertable .table tr td .bz h2,
.servertable .table tr td .bz p {
  line-height: 1em;
  margin: 10px 0;
}

.servertable .table tr td .bz header {
  vertical-align: middle;
  font-size: 14px;
  height: auto;
  border: none;
  padding: 0;
}

.servertable .table tr td .bz h2 {
  font-size: 14px;
  margin: 0;
}

.servertable .table tr td .bz p {
  padding-left: 11px;
}

.servertable .table tr td .bz p.time {
  margin: 50px 0 30px;
}

.servertable .table tr .td1111 {
  width: 170px;
  padding-left: 24px;
}

.servertable .table tr .text-left {
  width: 300px;
}
.servertable .table tr .text-left span {
  padding-left: 1em;
  font-size: 16px;
}

.servertable .table tr .text-right {
  padding-left: 10px;
}

.servertable .table tr .text-right span {
  padding-right: 2em;
  padding-left: 1em;
  font-size: 16px;
}

.servertable .table .btngroup {
  margin: 15px 0;
}

.servertable .table .btngroup a {
  color: white;
  font-size: 16px;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 6px;
  margin: 0 16px;
}

.servertable .table .btngroup a:hover {
  color: white;
  font-size: 16px;
}

.servertable .table .btngroup .blue {
  background-color: #488fe1;
}

.servertable .table .btngroup .blue:hover {
  background-color: #3183e3;
}

.servertable .table .btngroup .green {
  background-color: #5cd314;
}

.servertable .table .btngroup .green:hover {
  background-color: #47bc00;
}
.servertable .table a,
.servertable .table a:visited,
.servertable .table a:active,
.servertable .table a:link,
.servertable .table a:hover {
  color: #5093e2;
  font-size: 14px;
}
</style>
