<template>
  <div class="wrapper">
    <div class="table">
      <vxe-grid border stripe resizable auto-resize :columns="tableColumn" :data="tableData" :loading="loading"></vxe-grid>
      <vxe-pager
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :page-sizes="tablePage.pageSizes"
        :total="tablePage.totalResult"
        :layouts="layouts"
        @page-change="pageChange"
      ></vxe-pager>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true,
      },
      loading: false,
      tableColumn: [
        {
          type: 'seq',
          title: '序号',
          width: 100,
          align: 'center',
        },
        {
          field: 'oldPersonId',
          title: '服务对象',
          showOverflow: true,
          align: 'center',
          minWidth: '120',
        },
        {
          field: 'orgId',
          title: '服务公司',
          showOverflow: true,
          align: 'center',
          minWidth: '120',
        },
        {
          field: 'projectId',
          title: '服务项目',
          showOverflow: true,
          align: 'center',
          minWidth: '70',
        },
        {
          field: 'beginTime',
          title: '就餐时间',
          showOverflow: true,
          align: 'center',
          minWidth: '70',
        },
        {
          field: 'mealFee',
          title: '刷卡金额',
          showOverflow: true,
          align: 'center',
          minWidth: '70',
        },
        {
          field: 'satisfy',
          title: '满意度',
          showOverflow: true,
          align: 'center',
          minWidth: '70',
        }
      ],
      tableData: [],
    }
  },
  computed: {
    ...mapState(['layouts']),
  },
  watch: {},
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['mealRecord']),
    initView() {
      this.getLists()
    },
    getLists() {
      this.loading = true;
      this.mealRecord({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          mealPersonId: this.$route.params.id,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.tablePage.totalResult = +res.data.total;
        }
        this.loading = false;
      });
    },
    // 分页功能
    pageChange(item) {
      if (item.type == 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getLists()
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .datetime {
    display: flex;
    align-items: center;
    span {
      margin: 0 3px;
    }
  }
}
.sign i {
  margin-right: 3px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
